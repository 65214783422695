import Toaster from '@geneui/components/Toaster';

import { blobToFile } from '.';

export const blobToBase64 = ({ blob, name, maxSize, t }) =>
  new Promise((resolve, reject) => {
    const maxSizeInMB = maxSize / 1000000;
    if (blob.size > maxSize) {
      Toaster.error({
        title: t('failed-to-upload'),
        message: t('the-document-cannot-be-larger-than-fileSize-mb', {
          fileSize: maxSizeInMB,
        }),
        iconProps: {
          isFilled: true,
        },
      });
      reject(`The document cannot be larger than ${maxSizeInMB} MB.`);
    }

    const file = blobToFile(blob, name);
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result);
      }
    };
  });

export const uploadFileProps = ({ selectedImage, defaultSelectedImage }) =>
  selectedImage || !defaultSelectedImage
    ? { images: selectedImage ? [selectedImage] : [] }
    : { data: defaultSelectedImage };
