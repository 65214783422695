import React from 'react';

import Icon from '@geneui/components/Icon';

import sportsBook from '../../../assets/media/icons/sportsbook.svg';
import liveGames from '../../../assets/media/icons/live_games.svg';
import virtualGames from '../../../assets/media/icons/virtual_games.svg';
import skillGames from '../../../assets/media/icons/skill_games.svg';
import bettingGames from '../../../assets/media/icons/betting_games.svg';
import poolBettingGames from '../../../assets/media/icons/pool_betting_games.svg';
import slots from '../../../assets/media/icons/slots.svg';
import thirdPartyPoker from '../../../assets/media/icons/third_party_poker.svg';
import tableGames from '../../../assets/media/icons/table_games.svg';
import videoPoker from '../../../assets/media/icons/video_poker.svg';
import betConstructPoker from '../../../assets/media/icons/betconstruct_poker.svg';
import fantasySport from '../../../assets/media/icons/fantasy_sports.svg';
import vk from '../../../assets/media/icons/vk.svg';
import facebook from '../../../assets/media/icons/facebook.svg';
import youtube from '../../../assets/media/icons/youtube.svg';
import instagram from '../../../assets/media/icons/instagram.svg';
import telegram from '../../../assets/media/icons/telegram.svg';
import tiktok from '../../../assets/media/icons/tiktok.svg';
import linkedin from '../../../assets/media/icons/linkedin.svg';
import vimeo from '../../../assets/media/icons/vimeo.svg';
import X from '../../../assets/media/icons/x.svg';
import odnoklassniki from '../../../assets/media/icons/odnoklassniki.svg';
import accepted from '../../../assets/media/icons/accepted.svg';
import denied from '../../../assets/media/icons/denied.svg';
import pending from '../../../assets/media/icons/pending.svg';
import waiting from '../../../assets/media/icons/waiting.svg';
import reopen from '../../../assets/media/icons/reopen.svg';
import total_accepted from '../../../assets/media/icons/total_accepted.svg';
import total_denied from '../../../assets/media/icons/total_denied.svg';
import total_pending from '../../../assets/media/icons/total_pending.svg';
import note from '../../../assets/media/icons/note.svg';
import errorImage from '../../../assets/media/errorImage.png';

export const PRODUCT_ICONS = {
  SPORTS_BOOK: <img src={sportsBook} alt="sportsBook" />,
  BET_ON_POLITICS: <Icon type="bc-icon-fantasy-games" />,
  LIVE_GAMES: <img src={liveGames} alt="liveGames" />,
  VIRTUAL_GAMES: <img src={virtualGames} alt="virtualGames" />,
  SKILL_GAMES: <img src={skillGames} alt="skillGames" />,
  BETTING_GAMES: <img src={bettingGames} alt="bettingGames" />,
  POOL_BETTING_GAMES: <img src={poolBettingGames} alt="poolBettingGames" />,
  SLOTS: <img src={slots} alt="slots" />,
  THIRD_PARTY_POKER: <img src={thirdPartyPoker} alt="thirdPartyPoker" />,
  TABLE_GAMES: <img src={tableGames} alt="tableGames" />,
  VIDEO_POKER: <img src={videoPoker} alt="videoPoker" />,
  BET_CONSTRUCT_POKER: <img src={betConstructPoker} alt="betConstructPoker" />,
  FANTASY_SPORT: <img src={fantasySport} alt="fantasySport" />,
};

export const SOCIAL_ICONS = {
  FACEBOOK: <img className="socialListItemIconImage-bc" src={facebook} alt="facebook" />,
  X: <img className="socialListItemIconImage-bc" src={X} alt="X" />,
  VKONTAKTE: <img className="socialListItemIconImage-bc" src={vk} alt="vk" />,
  INSTAGRAM: <img className="socialListItemIconImage-bc" src={instagram} alt="instagram" />,
  YOUTUBE: <img className="socialListItemIconImage-bc" src={youtube} alt="youtube" />,
  ODNOKLASSNIKI: <img className="socialListItemIconImage-bc" src={odnoklassniki} alt="odnoklassniki" />,
  LINKEDIN: <img className="socialListItemIconImage-bc" src={linkedin} alt="linkedin" />,
  VIMEO: <img className="socialListItemIconImage-bc" src={vimeo} alt="vimeo" />,
  TELEGRAM: <img className="socialListItemIconImage-bc" src={telegram} alt="telegram" />,
  TIKTOK: <img className="socialListItemIconImage-bc" src={tiktok} alt="tiktok" />,
};

export const WITHDRAWALS_ICON = {
  DENIED: denied,
  PENDING: pending,
  WAITING: waiting,
  REOPEN: reopen,
  ACCEPTED: accepted,
  TOTAL_DENIED: total_denied,
  TOTAL_PENDING: total_pending,
  TOTAL_ACCEPTED: total_accepted,
};

export { note, errorImage };
