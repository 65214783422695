import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Form from '@geneui/components/Form';
import Icon from '@geneui/components/Icon';
import Button from '@geneui/components/Button';

import Loader from 'components/Loader';
import FormField from 'components/FormField';
import ReCaptchaComponent from 'components/ReCaptchaComponent';

import { useTranslator } from 'utils';
import { ENTER_KEY_CODE, VERIFICATION_CODE } from 'constants/defines';
import { FORGOT_PASSWORD_PHONE_NUMBER, RESEND_VERIFICATION } from 'configs/urls';
import { useConfig } from 'utils/hooks/useConfig';
import { useLoginMutation } from 'reduxStore/services/authApi';

const GOOGLE_RE_CAPTCHA_ACTION = 'login';

const SignInForm = ({ readOnly, signInButtonColors }) => {
  const {
    isFetching: isMainSetupLoading,
    config: { isSms, reCaptcha, reCaptchaKey },
  } = useConfig();
  const [login, { isLoading }] = useLoginMutation();

  const [isValid, setIsValid] = useState(true);
  const [signInState, setSignInState] = useState({
    username: null,
    password: null,
  });
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  const { t } = useTranslator();

  const sharedProps = {
    required: true,
    inputSize: 'big',
  };

  const signInAction = async token => {
    try {
      const response = await login({
        ...signInState,
        ...(token
          ? {
              reCaptcha: {
                token,
                action: GOOGLE_RE_CAPTCHA_ACTION,
              },
            }
          : {}),
      });

      const { result, notification } = response?.data ?? {};

      if (result?.message) {
        history.push(result.message);
      } else if (!result && notification) {
        const signInError = notification.find(({ title }) => title === 'signIn');
        if (signInError?.code === VERIFICATION_CODE) {
          history.push(RESEND_VERIFICATION);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleKeyboard = async (event, handleReCaptchaVerify) => {
    if (event.keyCode === ENTER_KEY_CODE && isValid) {
      handleReCaptchaVerify ? handleReCaptchaVerify() : await signInAction();
    }
  };

  const changeSignInState = e => {
    setSignInState(prevState => ({ ...prevState, ...e }));
  };

  const loginForm = handleReCaptchaVerify => (
    <>
      <Form
        disableFooter
        readOnly={readOnly}
        onValidationChange={setIsValid}
        onKeyUp={event => handleKeyboard(event, handleReCaptchaVerify)}
      >
        <FormField
          {...sharedProps}
          placeholder="Username"
          dataKey="username"
          value={signInState.username}
          onChange={changeSignInState}
          icon="bc-icon-user"
          className="email-input"
        />
        <FormField
          {...sharedProps}
          type={showPassword ? null : 'password'}
          dataKey="password"
          placeholder="Password"
          value={signInState.password}
          onChange={changeSignInState}
          icon="bc-icon-permission-48"
          className="pass-input"
          endAdornment={
            <Icon
              className="showPassword"
              type={showPassword ? 'bc-icon-activate-48' : 'bc-icon-inactivate-48'}
              onClick={() => setShowPassword(prev => !prev)}
            />
          }
        />
      </Form>
      {isSms ? (
        <Link className="agentSignBlockForgotPassword-bc" to={FORGOT_PASSWORD_PHONE_NUMBER}>
          {t('forgot-password')}?
        </Link>
      ) : null}
      <Button
        size="big"
        flexibility="full-width"
        className="login-holder-item-wrap-button"
        disabled={!isValid}
        onClick={() => (handleReCaptchaVerify ? handleReCaptchaVerify() : signInAction())}
        style={{ ...signInButtonColors }}
      >
        {t('sign-in')}
      </Button>
    </>
  );

  return (
    <>
      <Loader isBusy={isLoading || isMainSetupLoading} />
      <div className="agentSignBlockTitleGroup-bc">
        <h2 className="agentSignBlockTitle-bc">{t('Sign-In-to-Your-Account')}</h2>
      </div>
      <div className="agentSignBlockFormWrapper-bc">
        {reCaptcha && reCaptchaKey ? (
          <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
            <ReCaptchaComponent onSuccess={signInAction} action={GOOGLE_RE_CAPTCHA_ACTION}>
              {loginForm}
            </ReCaptchaComponent>
          </GoogleReCaptchaProvider>
        ) : (
          loginForm()
        )}
      </div>
    </>
  );
};

export default SignInForm;
