const initialState = {
  records: [],
  totalRecordsCount: undefined,
  isBusy: false,
};

const initialActionState = {
  isSucceeded: false,
  isBusy: false,
};

const initialGroups = {
  groupList: initialState,
  groupMembers: initialState,
  usersListByGroupId: initialState,
  deleteGroupActionState: initialActionState,
  editGroupActionState: initialActionState,
  selectUsersTableActionState: initialActionState,
  removeUsersFromGroupState: initialActionState,
};

const initialNetwork = {
  groups: initialGroups,
};

export default initialNetwork;
