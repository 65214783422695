import Toaster from '@geneui/components/Toaster';

import { RESPONSE_SUCCESS_INFO } from 'constants/defines';
import {
  CREATE_GROUP,
  DELETE_GROUP,
  GET_AFFILIATES_LIST_BY_GROUP_ID,
  GET_GROUPS_LIST,
  UPDATE_GROUP_DETAILS,
  DELETE_AFFILIATE_FROM_GROUP,
  ADD_TO_GROUP,
} from 'constants/api';
import { Connection } from 'utils';

const getNetworkGroups = params => Connection.post(GET_GROUPS_LIST, params);

const deleteNetworkGroup = async groupId => {
  const { result } = await Connection.post(DELETE_GROUP, { groupId });

  if (result) {
    Toaster.success(RESPONSE_SUCCESS_INFO);
  }

  return result;
};

const updateNetworkGroupDetails = async data => {
  const { result } = await Connection.post(UPDATE_GROUP_DETAILS, data);

  if (result) {
    Toaster.success(RESPONSE_SUCCESS_INFO);
  }

  return result;
};

const getUserListByGroupId = params => Connection.post(GET_AFFILIATES_LIST_BY_GROUP_ID, params);

const createNetworkGroup = async data => {
  const { result } = await Connection.post(CREATE_GROUP, data);

  if (result) {
    Toaster.success(RESPONSE_SUCCESS_INFO);
  }

  return result;
};

const addToNetworkGroup = async data => {
  const { result } = await Connection.post(ADD_TO_GROUP, data);

  if (result) {
    Toaster.success(RESPONSE_SUCCESS_INFO);
  }

  return result;
};

const deleteUsersFromNetworkGroup = async data => {
  const { result } = await Connection.post(DELETE_AFFILIATE_FROM_GROUP, data);

  if (result) {
    Toaster.success(RESPONSE_SUCCESS_INFO);
  }

  return result;
};

export default {
  getNetworkGroups,
  deleteNetworkGroup,
  updateNetworkGroupDetails,
  getUserListByGroupId,
  createNetworkGroup,
  addToNetworkGroup,
  deleteUsersFromNetworkGroup,
};
