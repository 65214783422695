import { cloneDeep } from 'lodash';
import { NOTIFICATION_STATUS } from 'sections/components/Notification/constants';

const { READ } = NOTIFICATION_STATUS;

const getNotifications = async ({ state, effects }, filterParams) => {
  let notification = cloneDeep(state.notification);

  notification.isBusy = true;
  state.notification = notification;

  const { needReset: omit, ...data } = filterParams;

  notification = cloneDeep(state.notification);
  try {
    const { result } = await effects.notification.getNotifications(data);

    if (result) {
      notification.records = [...(filterParams.needReset ? [] : state.notification.records), ...result.records];
      notification.totalRecordsCount = Number(result.totalRecordsCount);
      notification.totalUnreadUserNotificationsCount = Number(result.totalUnreadUserNotificationsCount);
    }
  } finally {
    notification.isBusy = false;
    state.notification = notification;
  }
};

const markNotificationsAsRead = async ({ state, effects }, params) => {
  let notification = cloneDeep(state.notification);

  notification.isBusy = true;
  state.notification = notification;

  notification = cloneDeep(state.notification);
  try {
    const { result } = await effects.notification.markNotificationsAsRead(params);

    if (result) {
      const totalUnreadUserNotificationsCount = state.notification.totalUnreadUserNotificationsCount;

      notification.records = notification.records.map(item => ({
        ...item,
        status: item.id === params?.userNotificationId || params?.isAll ? READ : item.status,
      }));
      notification.totalUnreadUserNotificationsCount =
        !params?.isAll && totalUnreadUserNotificationsCount > 1 ? totalUnreadUserNotificationsCount - 1 : 0;
    }
  } finally {
    notification.isBusy = false;
    state.notification = notification;
  }
};

const setSocketNotificationAction = ({ state }, data) => {
  let notification = cloneDeep(state.notification);

  notification.records.unshift(data);
  notification.totalRecordsCount++;
  notification.totalUnreadUserNotificationsCount++;

  state.notification = notification;
};

export default {
  getNotifications,
  markNotificationsAsRead,
  setSocketNotificationAction,
};
