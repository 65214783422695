import { cloneDeep } from 'lodash';

const layoutElementsWithChangedEnabled = (layoutElements, id, isEnable) => {
  const updatedLayoutElements = cloneDeep(layoutElements);
  const layoutElement = updatedLayoutElements.find(item => item.layoutElementId === id);
  layoutElement.isEnabled = isEnable;

  return [updatedLayoutElements, layoutElement];
};

const changeLayoutElements = async ({ state, effects }, data) => {
  const { isChecked, id } = data;
  let callData;

  [state.layout.elements, callData] = layoutElementsWithChangedEnabled(state.layout.elements, id, isChecked);

  try {
    const result = await effects.layout.saveLayoutElement({
      PartnerLayoutElement: callData,
    });

    if (!result) {
      [state.layout.elements] = layoutElementsWithChangedEnabled(state.layout.elements, id, !isChecked);
    }
  } catch (e) {
    [state.layout.elements] = layoutElementsWithChangedEnabled(state.layout.elements, id, !isChecked);
  }
};

const getLayoutElements = async ({ state, effects }, payload) => {
  const { result } = await effects.layout.getLayoutElement(payload);
  if (result) {
    state.layout = {
      ...state.layout,
      elements: result.map(item => ({
        ...item,
        isEnabled: Boolean(Number(item.isEnabled)),
        layoutElementId: Number(item.layoutElementId),
      })),
    };
  }
};

export default { getLayoutElements, changeLayoutElements };
