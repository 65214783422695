import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import useCarousel from 'utils/hooks/useCarousel';
import { isMobile } from 'utils';
import { useApp } from 'utils/hooks/useApp';
import { NAVIGATE_COUNT } from 'constants/defines';

const PaymentMethod = ({ pageColor }) => {
  const { paymentsIcons } = useApp();

  const [index, setIndex] = useState(0);

  const carousel = useCarousel();

  const navigate =
    paymentsIcons &&
    Math.ceil(isMobile() ? Object.keys(paymentsIcons).length / 2 : Object.keys(paymentsIcons).length / 5);

  const changeCarousel = useCallback(
    i => {
      carousel(i, '.slide');
      setIndex(i);
    },
    [carousel]
  );

  return (
    <div className="brandSlider-bc">
      <div className="wrapper-bc slideContainer">
        <div className="slide">
          <ul className="brandSliderWrapper-bc">
            {(Object.keys(paymentsIcons || []) || []).map((val, i) => (
              <li className="brandSliderEl-bc" key={i.toString()}>
                <div>
                  <img src={`/global/img/payments/${val}`} alt={val} />
                </div>
              </li>
            ))}
          </ul>
        </div>
        <ul className="brandSliderNav-bc">
          {navigate !== NAVIGATE_COUNT &&
            (Object.keys(paymentsIcons || []) || [])
              .slice(0, navigate)
              .map((val, i) => (
                <li
                  className={index === i ? 'active' : null}
                  style={{ backgroundColor: index === i ? pageColor : '' }}
                  key={i}
                  onClick={() => changeCarousel(i)}
                />
              ))}
        </ul>
      </div>
    </div>
  );
};

PaymentMethod.propTypes = {
  pageColor: PropTypes.string.isRequired,
};

export default PaymentMethod;
