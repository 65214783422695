import {
  GET_AFFILIATE_TOTAL_NGR,
  GET_PRODUCT_PER_GROUP_LIST,
  GET_PRODUCT_PLAYER_STATISTICS_LIST,
  GET_PRODUCT_REPORT_LIST,
  GET_PRODUCT_STATISTICS_BY_AFFILIATE_LIST,
  GET_PRODUCT_STATISTICS_BY_AFFILIATE_SUB,
  GET_PRODUCT_STATISTICS_DAILY_LIST,
  GET_PRODUCT_STATISTICS_DAILY_SUB,
  GET_PRODUCT_STATISTICS_LIST,
  GET_PRODUCT_STATISTICS_SUB,
  GET_SUB_AFFILIATE_STATISTIC,
  GET_ACTIVITY_STATISTIC_BY_USERS_LIST,
  GET_COUNTRY_BY_COUNTRY_LIST,
  GET_MONTH_BY_MONTH_REPORT_LIST,
  GET_REFERRAL_REPORT_LIST,
  GET_CORRECTION_LOG_STATISTICS,
  GET_USER_PERFORMANCE_REPORT,
  GET_REVENUE_BREAKDOWN_LIST,
  GET_MARKETING_SOURCE_MEDIA_REPORT,
  GET_MARKETING_SOURCE_LINKS_REPORT,
  GET_MEDIA_STATISTICS_PRO,
  GET_PLAYERS_LINKS_STATISTICS_LIST,
  GET_PLAYERS_LINKS_STATISTICS_DAILY_LIST,
  GET_LINK_META_TAGS_REPORT,
  GET_ACTIVITY_INFO_BY_META,
  GET_ACTIVITY_INFO_BY_LINKS_STATISTICS,
  GET_AGENT_TRANSACTION_LIST,
  GET_AGENTS_TRANSFERS,
  GET_AGENT_WALLET_INFO_AFTER_TRANSACTION,
  GET_PLAYER_WALLET_INFO_AFTER_TRANSACTION,
  GET_PLAYERS_TRANSFERS,
  GET_SUB_AGENT_REPORT,
  GET_PLAYERS_BETS,
  GET_BET_ODDS,
} from 'constants/api';
import { api, INVALIDATION_TAGS } from 'reduxStore/api';
import { addChildrenById } from 'utils';

export const reportsApi = api.injectEndpoints({
  endpoints: builder => ({
    getProductReport: builder.query({
      query: body => ({
        url: GET_PRODUCT_REPORT_LIST,
        method: 'POST',
        body,
      }),
      providesTags: [INVALIDATION_TAGS.GET_PRODUCT_REPORT],
    }),
    getProductPlayerStatistics: builder.query({
      query: body => ({
        url: GET_PRODUCT_PLAYER_STATISTICS_LIST,
        method: 'POST',
        body,
      }),
      providesTags: [INVALIDATION_TAGS.GET_PRODUCT_PLAYERS_STATISTICS],
    }),
    getProductStatisticsByAffiliate: builder.query({
      query: body => ({
        url: GET_PRODUCT_STATISTICS_BY_AFFILIATE_LIST,
        method: 'POST',
        body,
      }),
    }),
    getProductPerGroup: builder.query({
      query: () => ({
        url: GET_PRODUCT_PER_GROUP_LIST,
        method: 'POST',
      }),
    }),
    getProductStatisticsByAffiliateSub: builder.query({
      query: body => ({
        url: GET_PRODUCT_STATISTICS_BY_AFFILIATE_SUB,
        method: 'POST',
        body: body.subFilter,
      }),
      providesTags: [INVALIDATION_TAGS.GET_PRODUCT_AFFILIATE_STATISTICS_SUB],
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (data?.result) {
          const expandedItem = data.result.records[0];

          dispatch(
            reportsApi.util.updateQueryData('getProductStatisticsByAffiliate', body.parentDefaultFilter, draft => {
              draft.result.records = draft.result.records.reduce(
                (updatedList, listItem) => [
                  ...updatedList,
                  listItem.affiliateId === expandedItem.affiliateId ? { ...listItem, ...expandedItem } : listItem,
                ],
                []
              );
            })
          );
        }
      },
    }),
    getProductStatistics: builder.query({
      query: body => ({
        url: GET_PRODUCT_STATISTICS_LIST,
        method: 'POST',
        body,
      }),
      providesTags: [INVALIDATION_TAGS.GET_PRODUCT_STATISTICS],
    }),
    getProductStatisticsSub: builder.query({
      query: body => ({
        url: GET_PRODUCT_STATISTICS_SUB,
        method: 'POST',
        body: body.subFilter,
      }),
      providesTags: [INVALIDATION_TAGS.GET_PRODUCT_STATISTICS_SUB],
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (data?.result) {
          const expandedItem = data?.result.records[0];

          dispatch(
            reportsApi.util.updateQueryData('getProductStatistics', body.parentDefaultFilter, draft => {
              draft.result.records = draft.result.records.reduce(
                (updatedList, listItem) => [
                  ...updatedList,
                  listItem.playerId === expandedItem.playerId ? { ...listItem, ...expandedItem } : listItem,
                ],
                []
              );
            })
          );
        }
      },
    }),
    getProductStatisticsDaily: builder.query({
      query: body => ({
        url: GET_PRODUCT_STATISTICS_DAILY_LIST,
        method: 'POST',
        body,
      }),
      providesTags: [INVALIDATION_TAGS.GET_PRODUCT_STATISTICS_DAILY],
    }),
    getProductStatisticsDailySub: builder.query({
      query: body => ({
        url: GET_PRODUCT_STATISTICS_DAILY_SUB,
        method: 'POST',
        body: body.subFilter,
      }),
      providesTags: [INVALIDATION_TAGS.GET_PRODUCT_STATISTICS_DAILY_SUB],
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (data?.result) {
          const expandedItem = data?.result.records[0];

          dispatch(
            reportsApi.util.updateQueryData('getProductStatisticsDaily', body.parentDefaultFilter, draft => {
              draft.result.records = draft.result.records.reduce(
                (updatedList, listItem) => [
                  ...updatedList,
                  listItem.date === expandedItem.date ? { ...listItem, ...expandedItem } : listItem,
                ],
                []
              );
            })
          );
        }
      },
    }),
    getSubAffiliateStatistic: builder.query({
      query: body => ({
        url: GET_SUB_AFFILIATE_STATISTIC,
        method: 'POST',
        body: body.filterData,
      }),
      providesTags: [INVALIDATION_TAGS.GET_SUB_AFFILIATE_STATISTICS],
      transformResponse: ({ result }, _, arg) => {
        if (arg?.prevList) {
          return addChildrenById(arg.filterData.filter.parentAffiliateId.value, result.records, null, arg.prevList);
        }

        if (!Object.keys(result).length || !result.records.subList) {
          return result.records;
        }

        return [
          {
            ...result.records[0],
            children: result.records.subList,
          },
        ];
      },
    }),
    getActivitiesByUsers: builder.query({
      query: credentials => ({
        url: GET_ACTIVITY_STATISTIC_BY_USERS_LIST,
        method: 'POST',
        body: credentials,
      }),
    }),
    getCountriesReportList: builder.query({
      query: credentials => ({
        url: GET_COUNTRY_BY_COUNTRY_LIST,
        method: 'POST',
        body: credentials,
      }),
      providesTags: [INVALIDATION_TAGS.GET_COUNTRIES_REPORT_LIST],
    }),
    getMonthlyReportList: builder.query({
      query: credentials => ({
        url: GET_MONTH_BY_MONTH_REPORT_LIST,
        method: 'POST',
        body: credentials,
      }),
      providesTags: [INVALIDATION_TAGS.GET_MONTHLY_REPORT_LIST],
    }),
    getReferralReportList: builder.query({
      query: body => ({
        url: GET_REFERRAL_REPORT_LIST,
        method: 'POST',
        body,
      }),
    }),
    getWalletReportList: builder.query({
      query: credentials => ({
        url: GET_CORRECTION_LOG_STATISTICS,
        method: 'POST',
        body: credentials,
      }),
    }),
    getUserPerformanceReport: builder.query({
      query: body => ({
        url: GET_USER_PERFORMANCE_REPORT,
        method: 'POST',
        body,
      }),
    }),
    getRevenueBreakdownList: builder.query({
      query: body => ({
        url: GET_REVENUE_BREAKDOWN_LIST,
        method: 'POST',
        body,
      }),
      providesTags: [INVALIDATION_TAGS.GET_REVENUE_BREAKDOWN_LIST],
    }),
    getMarketingSourceMediaReport: builder.query({
      query: body => ({
        url: GET_MARKETING_SOURCE_MEDIA_REPORT,
        method: 'POST',
        body,
      }),
    }),
    getMarketingSourceLinksReport: builder.query({
      query: body => ({
        url: GET_MARKETING_SOURCE_LINKS_REPORT,
        method: 'POST',
        body,
      }),
    }),
    getMediaStatisticsPro: builder.query({
      query: body => ({
        url: GET_MEDIA_STATISTICS_PRO,
        method: 'POST',
        body,
      }),
      providesTags: [INVALIDATION_TAGS.GET_MEDIA_STATISTICS_PRO],
    }),
    getPlayersLinksStatisticsList: builder.query({
      query: body => ({
        url: GET_PLAYERS_LINKS_STATISTICS_LIST,
        method: 'POST',
        body,
      }),
    }),
    getPlayersLinksStatisticsDailyList: builder.query({
      query: body => ({
        url: GET_PLAYERS_LINKS_STATISTICS_DAILY_LIST,
        method: 'POST',
        body,
      }),
    }),
    getActivityInfoByLinksStatistics: builder.query({
      query: body => ({
        url: GET_ACTIVITY_INFO_BY_LINKS_STATISTICS,
        method: 'POST',
        body,
      }),
    }),
    getLinkMetaTagsReport: builder.query({
      query: body => ({
        url: GET_LINK_META_TAGS_REPORT,
        method: 'POST',
        body,
      }),
    }),
    getActivityInfoByMeta: builder.query({
      query: body => ({
        url: GET_ACTIVITY_INFO_BY_META,
        method: 'POST',
        body,
      }),
    }),
    getAffiliateTotalNGR: builder.query({
      query: body => ({
        url: GET_AFFILIATE_TOTAL_NGR,
        method: 'POST',
        body,
      }),
    }),
    getSubAgentReport: builder.query({
      query: body => ({
        url: GET_SUB_AGENT_REPORT,
        method: 'POST',
        body: body.filterData,
      }),
      transformResponse: ({ result }, _, arg) => {
        if (arg?.prevList) {
          return addChildrenById(arg.filterData.filter.parentAffiliateId.value, result.records, null, arg.prevList);
        }

        if (result.records && result.records.subList) {
          result.records = [
            {
              ...result.records[0],
              children: result.records.subList,
            },
          ];
        }

        return result.records ?? result;
      },
    }),
    getAgentTransactionList: builder.query({
      query: body => ({
        url: GET_AGENT_TRANSACTION_LIST,
        method: 'POST',
        body,
      }),
      providesTags: [INVALIDATION_TAGS.GET_AGENT_TRANSACTION_LIST],
    }),
    getAgentsTransferList: builder.query({
      query: body => ({
        url: GET_AGENTS_TRANSFERS,
        method: 'POST',
        body,
      }),
      providesTags: [INVALIDATION_TAGS.GET_AGENTS_TRANSFER_LIST],
    }),
    getPlayersTransferList: builder.query({
      query: body => ({
        url: GET_PLAYERS_TRANSFERS,
        method: 'POST',
        body,
      }),
      providesTags: [INVALIDATION_TAGS.GET_PLAYERS_TRANSFER_LIST],
    }),
    getAgentOrPlayerWalletInfoAfterTransaction: builder.query({
      query: ({ transactionId, agent }) => ({
        url: agent ? GET_AGENT_WALLET_INFO_AFTER_TRANSACTION : GET_PLAYER_WALLET_INFO_AFTER_TRANSACTION,
        method: 'POST',
        body: { transactionId },
      }),
      providesTags: [
        INVALIDATION_TAGS.GET_AGENT_WALLET_INFO_AFTER_TRANSACTION,
        INVALIDATION_TAGS.GET_PLAYER_WALLET_INFO_AFTER_TRANSACTION,
      ],
    }),
    getPlayersBets: builder.query({
      query: body => ({
        url: GET_PLAYERS_BETS,
        method: 'POST',
        body,
      }),
    }),
    getBetOdds: builder.query({
      query: body => ({
        url: GET_BET_ODDS,
        method: 'POST',
        body,
      }),
      providesTags: [INVALIDATION_TAGS.GET_BET_ODDS],
    }),
  }),
});

export const {
  useGetProductPerGroupQuery,
  useGetAffiliateTotalNGRQuery,
  useLazyGetSubAffiliateStatisticQuery,
  useLazyGetSubAgentReportQuery,
  useLazyGetProductStatisticsQuery,
  useLazyGetProductStatisticsDailyQuery,
  useLazyGetProductStatisticsDailySubQuery,
  useLazyGetProductStatisticsSubQuery,
  useLazyGetProductStatisticsByAffiliateSubQuery,
  useLazyGetProductReportQuery,
  useLazyGetProductStatisticsByAffiliateQuery,
  useLazyGetProductPlayerStatisticsQuery,
  useLazyGetActivitiesByUsersQuery,
  useGetCountriesReportListQuery,
  useGetMonthlyReportListQuery,
  useGetReferralReportListQuery,
  useGetWalletReportListQuery,
  useLazyGetWalletReportListQuery,
  useLazyGetUserPerformanceReportQuery,
  useGetRevenueBreakdownListQuery,
  useLazyGetMarketingSourceMediaReportQuery,
  useLazyGetMarketingSourceLinksReportQuery,
  useLazyGetMediaStatisticsProQuery,
  useLazyGetPlayersLinksStatisticsListQuery,
  useGetActivityInfoByLinksStatisticsQuery,
  useLazyGetPlayersLinksStatisticsDailyListQuery,
  useLazyGetLinkMetaTagsReportQuery,
  useGetActivityInfoByMetaQuery,
  useLazyGetAgentTransactionListQuery,
  useLazyGetAgentsTransferListQuery,
  useLazyGetPlayersTransferListQuery,
  useGetAgentOrPlayerWalletInfoAfterTransactionQuery,
  useLazyGetPlayersBetsQuery,
  useGetBetOddsQuery,
} = reportsApi;
