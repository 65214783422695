import Connection from 'utils/connection';
import {
  GIVE_CPA_TO_AFFILIATE,
  GIVE_HYBRID_TO_AFFILIATE,
  CHANGE_AFFILIATE_COMMISSION_PLAN,
  SET_AFFILIATE_REFERRAL_COMMISSION,
  CHANGE_GROUP_COMMISSION_PLAN,
} from 'constants/api';
import { cloneDeep } from 'lodash';
import { DEFAULT_COMMISSION_PLAN_TYPE } from 'constants/defines';

export const saveCpa = async (param, affiliateId) => {
  const { id, endDate } = param;
  const data = { affiliateId, cpaId: id, endDate };

  return await Connection.post(GIVE_CPA_TO_AFFILIATE, data);
};

export const saveRevenue = async (param, affiliateId) => {
  const { id } = param;
  const affiliateList = !Array.isArray(affiliateId) ? [affiliateId] : affiliateId;
  const data = { affiliateList, planTypeId: id };

  return await Connection.post(CHANGE_AFFILIATE_COMMISSION_PLAN, data);
};

export const saveHybrid = async (param, affiliateId) => {
  const { id } = param;
  const data = { affiliateId, hybridId: id };

  return await Connection.post(GIVE_HYBRID_TO_AFFILIATE, data);
};

export const saveReferral = async (param, affiliateId) => {
  const { id } = param;
  const data = { affiliateId, referralCommissionId: id };

  return await Connection.post(SET_AFFILIATE_REFERRAL_COMMISSION, data);
};

export const saveGroupCommission = async (param, groupId) => {
  const { id } = param;
  const data = { groupId, planTypeId: id };

  return await Connection.post(CHANGE_GROUP_COMMISSION_PLAN, data);
};

const getCpaList = async ({ state, effects }) => {
  let commisionPlans = cloneDeep(state.commisionPlans);
  commisionPlans.cpaList.isBusy = true;
  state.commisionPlans = commisionPlans;

  const { result } = await effects.commisionPlans.getCpaList();

  commisionPlans = cloneDeep(state.commisionPlans);
  if (result) {
    commisionPlans.cpaList.list = result;
  }

  commisionPlans.cpaList.isBusy = false;
  state.commisionPlans = commisionPlans;
};

const deleteCPA = async ({ state, effects }, id) => {
  let commisionPlans = cloneDeep(state.commisionPlans);
  commisionPlans.cpaList.isBusy = true;
  state.commisionPlans = commisionPlans;

  const result = await effects.commisionPlans.deleteCPA(id);

  commisionPlans = cloneDeep(state.commisionPlans);
  if (result) {
    commisionPlans.cpaList.list = commisionPlans.cpaList.list.filter(({ id: cpaId }) => parseInt(cpaId) !== id);
  }

  commisionPlans.cpaList.isBusy = false;
  state.commisionPlans = commisionPlans;
};

const changeDefaultCommisionPlan = async ({ state, effects }, params) => {
  const { id, isCPA, type } = params;
  const commisionPlanType = isCPA ? 'cpaList' : 'revenueList';

  let commisionPlans = cloneDeep(state.commisionPlans);
  commisionPlans[commisionPlanType].isBusy = true;
  state.commisionPlans = commisionPlans;

  const result = await effects.commisionPlans.changeDefaultCommisionPlan({
    id,
    type,
  });

  commisionPlans = cloneDeep(state.commisionPlans);
  if (result) {
    commisionPlans[commisionPlanType].list = commisionPlans[commisionPlanType].list.map(item => ({
      ...item,
      isDefault: parseInt(item.id) === id,
    }));
  }

  commisionPlans[commisionPlanType].isBusy = false;
  state.commisionPlans = commisionPlans;
};

const changeDefaultCommisionPlanToCPA = async ({ state, effects }, id) => {
  const params = {
    id,
    isCPA: true,
    type: DEFAULT_COMMISSION_PLAN_TYPE.CPA,
  };
  await changeDefaultCommisionPlan({ state, effects }, params);
};

const changeDefaultCommisionPlanToRevenue = async ({ state, effects }, id) => {
  const params = {
    id,
    isCPA: false,
    type: DEFAULT_COMMISSION_PLAN_TYPE.REVENUE,
  };
  await changeDefaultCommisionPlan({ state, effects }, params);
};

const getRevenueList = async ({ state, effects }) => {
  let commisionPlans = cloneDeep(state.commisionPlans);
  commisionPlans.revenueList.isBusy = true;
  state.commisionPlans = commisionPlans;

  const { result } = await effects.commisionPlans.getRevenueList();

  commisionPlans = cloneDeep(state.commisionPlans);
  if (result) {
    commisionPlans.revenueList.list = result;
  }

  commisionPlans.revenueList.isBusy = false;
  state.commisionPlans = commisionPlans;
};

const deleteRevenue = async ({ state, effects }, id) => {
  let commisionPlans = cloneDeep(state.commisionPlans);
  commisionPlans.revenueList.isBusy = true;
  state.commisionPlans = commisionPlans;

  const result = await effects.commisionPlans.deleteRevenue(id);

  commisionPlans = cloneDeep(state.commisionPlans);
  if (result) {
    commisionPlans.revenueList.list = commisionPlans.revenueList.list.filter(
      ({ id: revenueId }) => parseInt(revenueId) !== id
    );
  }

  commisionPlans.revenueList.isBusy = false;
  state.commisionPlans = commisionPlans;
};

const archiveRevenue = async ({ state, effects }, id) => {
  let commisionPlans = cloneDeep(state.commisionPlans);
  commisionPlans.revenueList.isBusy = true;
  state.commisionPlans = commisionPlans;

  const result = await effects.commisionPlans.archiveRevenue({
    commissionTypeId: id,
    isArchived: true,
  });

  commisionPlans = cloneDeep(state.commisionPlans);
  if (result) {
    commisionPlans.revenueList.list = commisionPlans.revenueList.list.filter(
      ({ id: commissionTypeId }) => commissionTypeId !== id
    );
  }

  commisionPlans.revenueList.isBusy = false;
  state.commisionPlans = commisionPlans;
};

const getCommissionByUser = async ({ state, effects }, filter) => {
  let commissionPlans = cloneDeep(state.commisionPlans);
  commissionPlans.commissionUser.revenue.isBusyRevenue = true;
  state.commisionPlans = commissionPlans;

  const { result } = await effects.commisionPlans.getCommissionByUser(filter);

  commissionPlans = cloneDeep(state.commisionPlans);

  if (result) {
    commissionPlans.commissionUser.revenue.data = result;
  }

  commissionPlans.commissionUser.revenue.isBusyRevenue = false;
  state.commisionPlans = commissionPlans;
};

const getCpaCommissionByUser = async ({ state, effects }, filter) => {
  let commissionPlans = cloneDeep(state.commisionPlans);
  commissionPlans.commissionUser.revenue.isBusyCpa = true;
  state.commisionPlans = commissionPlans;

  const { result } = await effects.commisionPlans.getCpaCommissionByUser(filter);

  commissionPlans = cloneDeep(state.commisionPlans);

  if (result) {
    commissionPlans.commissionUser.cpa.data = result;
  }

  commissionPlans.commissionUser.revenue.isBusyCpa = false;
  state.commisionPlans = commissionPlans;
};

const getReferralCommissionByUser = async ({ state, effects }, filter) => {
  let commissionPlans = cloneDeep(state.commisionPlans);
  commissionPlans.commissionUser.referral.isBusyReferral = true;
  state.commisionPlans = commissionPlans;

  const { result } = await effects.commisionPlans.getReferralCommissionByUser(filter);

  commissionPlans = cloneDeep(state.commisionPlans);

  if (result) {
    commissionPlans.commissionUser.referral.data = result;
  }

  commissionPlans.commissionUser.referral.isBusyReferral = false;
  state.commisionPlans = commissionPlans;
};

export default {
  getCpaList,
  deleteCPA,
  changeDefaultCommisionPlanToCPA,
  getRevenueList,
  deleteRevenue,
  changeDefaultCommisionPlanToRevenue,
  archiveRevenue,
  getCommissionByUser,
  getCpaCommissionByUser,
  getReferralCommissionByUser,
};
