import account from './account';
import messages from './messages';
import agentsActions from './agents';
import notification from './notification';
import affiliatesActions from './affiliates';
import geolocation from './geolocation';
import layout from './layout';
import profile from './profile';
import postBack from './postback';
import * as deploy from './deploy';
import testimonials from './testimonials';
import players from './players';
import verifications from './verifications';
import commissionPlans from './commission';
import network from './network';
import contentManagement from './contentManagement';

/**
 * TODO
 * functions must be closed "Action" with word
 */
export default {
  ...account,
  ...messages,
  ...notification,
  ...agentsActions,
  ...affiliatesActions,
  ...geolocation,
  ...profile,
  ...layout,
  ...postBack,
  ...deploy,
  ...testimonials,
  ...players,
  ...verifications,
  ...commissionPlans,
  ...network,
  ...contentManagement,
};
