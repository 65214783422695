const getPlayerBalance = async ({ state, effects }, playerId) => {
  state.players = {
    ...state.players,
    playerBalance: {
      ...state.players.playerBalance,
      [playerId]: { isBusy: true },
    },
  };
  try {
    const { result: balances } = await effects.players.getPlayerBalanceById(playerId);
    state.players = {
      ...state.players,
      playerBalance: {
        ...state.players.playerBalance,
        [playerId]: {
          balances,
          isBusy: false,
        },
      },
    };
  } catch {
    state.players = {
      ...state.players,
      playerBalance: {
        ...state.players.playerBalance,
        [playerId]: { isBusy: false },
      },
    };
  }
};

const resetPlayerBalance = ({ state }) => {
  state.players = {
    ...state.players,
    playerBalance: {},
  };
};

export default { getPlayerBalance, resetPlayerBalance };
