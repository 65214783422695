import React from 'react';

import moment from 'moment';
import Cookies from 'js-cookie';

import Toaster from '@geneui/components/Toaster';

import isMobile from 'utils/isMobile';
import { dateFormat, defaultDirection } from 'configs';
import COLORS from 'constants/defines/colors';
import {
  DATE,
  ALL_DATA,
  SELECTED,
  STRING_TYPE,
  PRODUCT_LIST,
  FILTER_ACTIONS,
  COMMISSION_PlAN_NET_DEPOSIT,
  DEFAULT_REPORTS_FILTERS_PARAMS,
  DEFAULT_REPORTS_FILTERS_PARAMS_ALL_LIST,
  MIN_AGE,
  MAX_AGE,
} from 'constants/defines';
import { CHECK_IS_NUMBER, NUMBER_FORMAT } from 'constants/defines/regExpStrings';

const unshiftItemInArray = (array, item) => {
  array.unshift(item);

  return array;
};

const getRoleShortTitle = (title = '') => {
  return title
    .split(/\s|-/)
    .reduce((acc, item, i, array) => (acc += array.length > 1 ? item.slice(0, 1) : item.slice(0, 2)), '')
    .toUpperCase();
};

const blobToFile = (theBlob = {}, fileName) => {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;

  return theBlob;
};

const capitalize = string => (string.length ? string[0].toLowerCase() + string.slice(1) : '');

const selectUnSelectHandler = (id, type, selectedId, selectedAvailableId) => {
  const currentIds = type === SELECTED ? selectedId : selectedAvailableId;
  const newArray = currentIds.includes(id) ? currentIds.filter(currentId => currentId !== id) : [...currentIds, id];

  return type === SELECTED ? [newArray, []] : [[], newArray];
};

const getArrayDifferenceByKey = (currentArray, newArray, differentKey, uniqueKey) => {
  return newArray.filter(item =>
    currentArray.find(other => other[uniqueKey] === item[uniqueKey] && item[differentKey] !== other[differentKey])
  );
};

export const isSafari = () => {
  const chromeAgent = navigator.userAgent.indexOf('Chrome') > -1;
  const safariAgent = navigator.userAgent.indexOf('Safari') > -1;
  return !chromeAgent && safariAgent;
};

const copyToClipboard = async value => {
  const iconProps = {
    isFilled: true,
  };
  if (!navigator.clipboard) {
    const input = document.createElement('input');
    input.value = value;
    document.body.appendChild(input);
    input.select();

    document.execCommand('copy')
      ? Toaster.success({ title: 'copied', message: value, iconProps })
      : Toaster.error({ title: 'error', message: '', iconProps });

    document.body.removeChild(input);
  } else {
    try {
      await navigator.clipboard.writeText(value);
      Toaster.success({ title: 'copied', message: value, iconProps });
    } catch (e) {
      Toaster.error({ title: 'error', message: '', iconProps });
    }
  }
};

export const copyToClipboardWithPromise = async promise => {
  const iconProps = {
    isFilled: true,
  };

  try {
    const clipboard = new window.ClipboardItem({
      'text/plain': promise,
    });
    await navigator.clipboard.write([clipboard]);
    const value = await promise;
    Toaster.success({ title: 'copied', message: value, iconProps });
  } catch (error) {
    Toaster.error({ title: 'error', message: error, iconProps });
  }
};

const getDate = date => moment(date).format(dateFormat);

const getCookie = cname => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return null;
};

const checkValidCommissionProducts = (conflict, rows, pId, amountKey = 'percent') => {
  if (!rows.length) {
    conflict.existed = true;

    return conflict;
  }

  const { existed, ...errorData } = checkValidCommission(rows, amountKey, 100);
  conflict.existed = existed;
  conflict.products[pId] = Object.values(errorData);

  return conflict;
};

/**
 * checkValidCommission
 * function check is commission rows details are valid,
 * work for checking rows with percent amount and amount for money
 * amountKey and maxAmount is configurable
 *
 * @param rows
 * @param amountKey
 * @param maxAmount default value 100 (special for check percent)
 */
const checkValidCommission = (rows, amountKey, maxAmount = 100) => {
  let max, min;

  const conflict = { existed: false };

  for (let i = 0, len = rows.length; i < len; i++) {
    const last = len - 1;

    const prevRows = rows[i - 1] || {};
    const nextRows = rows[i + 1] || {};

    const prevLossMax = Number(prevRows.lossMax);
    const nextLossMin = Number(nextRows.lossMin);

    const prevAmount = Number(prevRows[amountKey]);
    const nextAmount = Number(nextRows[amountKey]);

    const lossMin = Number(rows[i].lossMin);
    const lossMax = Number(rows[i].lossMax);

    const amount = Number(rows[i][amountKey]) || 0;

    conflict[i] = {};

    /**
     * lossMin check
     * @type {number}
     */
    min = i === 0 ? 0 : prevLossMax;
    max = i === last ? Infinity : lossMax;
    if (lossMin !== min || lossMin >= max) {
      conflict.existed = true;
      conflict[i].lossMin = true;
    }

    /**
     * lossMax check
     */
    min = lossMin;
    max = i === last ? Infinity : nextLossMin;
    if ((lossMax <= min || lossMax !== max) && !(i === last && isNaN(lossMax))) {
      conflict.existed = true;
      conflict[i].lossMax = true;
    }

    /**
     * percent check
     */
    min = i === 0 ? 0 : prevAmount;
    max = i === last ? maxAmount : nextAmount;
    if (!amount || amount < min || (i !== last && amount > max) || amount > max) {
      if (!(i === 0 && amount === 0)) {
        conflict.existed = true;
        conflict[i][amountKey] = true;
      }
    }
  }

  return conflict;
};

const mapForSaveCommissionStructure = (structure, revenueShareType) => {
  const cleanStructure = {};

  for (const key in structure) {
    if (structure.hasOwnProperty(key)) {
      cleanStructure[key] = {
        ...structure[key],
        commissions: structure[key].commissions.filter(({ productId }) =>
          revenueShareType === COMMISSION_PlAN_NET_DEPOSIT
            ? parseInt(productId) === PRODUCT_LIST.SPORTS_BOOK
            : productId !== ALL_DATA
        ),
      };
    }
  }

  return cleanStructure;
};

/**
 * download file
 * @param baseUrl base64format link
 * @param fileName
 * @param fileType (png txt)
 *
 */
const downloadFile = (baseUrl, fileName, fileType) => {
  const downloadLink = document.createElement('a');

  downloadLink.href = baseUrl;
  downloadLink.download = fileName.concat('.', fileType);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

const getExtensionByName = filename => {
  const nameArray = filename.split('.');

  return nameArray[nameArray.length - 1].toUpperCase() || '';
};

const fileSizeDisplay = bytes => {
  if (!bytes || bytes < 1) return '0 Byte';

  const sizes = ['Byte', 'KB', 'MB', 'GB', 'TR'];
  const powerOfTwo = Math.floor(Math.log2(bytes) / 10);
  const i = powerOfTwo > 4 ? 4 : powerOfTwo;

  return Math.floor(bytes / Math.pow(2, i * 10)) + ' ' + sizes[i];
};

/**
 * change current cpa object structure for create
 * @param cpa
 * @returns {{structure: {}}}
 */
const covertCpaStructureForEdit = cpa => {
  const newCpa = { ...cpa, structure: {} };

  for (const key in cpa.structure) {
    if (cpa.structure.hasOwnProperty(key)) {
      const paymentConditions = Object.keys(cpa.structure[key].paymentConditions).reduce(
        (acc, value) => ({
          ...acc,
          [value]: Object.values(cpa.structure[key].paymentConditions[value]),
        }),
        {}
      );

      newCpa.structure[key] = {
        isVerified: parseInt(cpa.structure[key].isVerified) ?? 0,
        products: [...cpa.structure[key].products],
        conditions: Object.values(cpa.structure[key].conditions),
        paymentConditions: paymentConditions,
      };
    }
  }

  return newCpa;
};

/**
 * covert current revenue object structure for create
 * @param revenue
 * @returns {{structure: {}}}
 */
const covertRevenueStructureForEdit = revenue => {
  const newRevenue = { ...revenue, structure: {} };

  for (const key in revenue.structure) {
    if (revenue.structure.hasOwnProperty(key)) {
      newRevenue.structure[key] = {
        commissions: [
          {
            ...revenue.structure[key].commissions[0],
            productId: ALL_DATA,
            productName: 'ranges-for-all-products',
          },
          ...revenue.structure[key].commissions,
        ],
        conditions: [...revenue.structure[key].conditions],
      };
    }
  }

  return newRevenue;
};

const startUpload = ({ uploadedFiles }) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(uploadedFiles);
    }, 2000);
  });
};

const outsideFilters = (location = {}, defaultFilters = {}) => {
  const { state } = location;

  const filterData = state ? JSON.parse(state) : DEFAULT_REPORTS_FILTERS_PARAMS;

  return {
    ...filterData,
    filter: {
      ...defaultFilters.filter,
      ...filterData.filter,
    },
  };
};

const getYesterday = () => moment().subtract(1, 'day').format(dateFormat);
const getToday = () => moment().format(dateFormat);

const getCurrentMonth = format => {
  const date = moment();

  return {
    to: date.format(format || dateFormat),
    from: date.startOf('month').format(format || dateFormat),
  };
};

const getFilterCurrentMonthForAll = (date = 'date') => {
  const { from, to } = getCurrentMonth();

  return {
    ...DEFAULT_REPORTS_FILTERS_PARAMS_ALL_LIST,
    filter: {
      [date]: {
        from,
        to,
        valueLabel: from.concat(' and ', to),
        action: FILTER_ACTIONS.BETWEEN,
        label: 'month',
      },
    },
  };
};

const createMarkup = (text, partnerName) => {
  const regex = /{partnerName}/gi;

  return { __html: (text || '').replace(regex, `${partnerName}`) };
};

/**
 * show red when amount is negative
 * @param amount
 * @returns {node}
 */
const negativeAmountForReport = amount => <span style={amount < 0 ? { color: COLORS.DELETE } : {}}>{amount}</span>;

const addChildrenById = (id, records, nestedData, list) =>
  (nestedData || list).map(item =>
    item.affiliateId === id
      ? { ...item, children: records }
      : item.children
      ? { ...item, children: addChildrenById(id, records, item.children, list) }
      : { ...item }
  );

const indexOfFilter = (filters, filterKeys, defaultFilters) => {
  const data = {};

  Object.keys(filters).map(key => {
    const foundKeys = Object.keys(defaultFilters).find(defKey => {
      if (key === DATE) {
        return defaultFilters[defKey].valueLabel === filters[key].valueLabel;
      } else {
        return defaultFilters[defKey].value === filters[key].value;
      }
    });

    if (foundKeys) {
      if (!filterKeys.find(fKey => fKey === key)) {
        data[key] = filters[key];
      }
    } else {
      data[key] = filters[key];
    }

    return key;
  });

  return { filter: data };
};

const priorityOrderBy = columns => {
  return columns.sort((a, b) => (a.priority ? (a.priority > b.priority ? 1 : -1) : 0));
};

const formatSimpleNumber = (num, fixCount) => {
  const fixed = fixCount === undefined ? (num.split('.')[1] || []).length : fixCount;

  return parseFloat(num).toFixed(fixed).replace(NUMBER_FORMAT, '$1,');
};

/**
 * check string is number
 * @param str
 * @returns {boolean}
 */
const checkIsNumber = str => CHECK_IS_NUMBER.test(str);

const formatNumber = (num = 0, fixCount) => {
  if (num == null) return '-';

  const str = num.toString();
  const arr = str.split(' ');
  return arr[1]
    ? arr[1] !== 'undefined'
      ? `${arr[0]} ${formatSimpleNumber(arr[1], fixCount)}`
      : '-'
    : formatSimpleNumber(str, fixCount);
};

const dropDownDataFormatter = (result, id, name) =>
  result.map(item => ({
    ...item,
    [name]: item[id].concat('-', item[name]),
  }));

const documentDirection = () => document.documentElement.dir === defaultDirection;

const documentDirectionChange = (allLanguages, activeLang) => {
  const ln = allLanguages.find(({ id }) => id === activeLang.replace('-', '_'));
  const { direction = defaultDirection } = ln || {};

  document.documentElement.lang = activeLang.split('-')[0];
  document.documentElement.dir = direction;
  return direction;
};

const translateTextChange = text => text.toLowerCase().replace(/ /g, '-');

const translateConstants = (array, key, t) => array.map(item => ({ ...item, [key]: t(item[key]) }));

const translateToasterMessage = (obj, t) => ({
  title: t(obj.title),
  message: t(obj.message),
  iconProps: {
    isFilled: true,
  },
});

const groupBySteps = data =>
  data.reduce((acc = {}, item) => {
    const { step } = item;

    return {
      ...acc,
      [step]: [...(acc[step] || []), item],
    };
  }, {});

const stopPropagationHandler = e => {
  !isMobile() && e.stopPropagation();
};

const firstCharacterUpperCase = text => {
  return text.charAt(0).toUpperCase() + text.substr(1);
};

const range = length => new Array(length).fill().map((_, i) => i);

const objectLanguageWithoutSubject = obj =>
  Object.keys(obj).reduce((acc, crr) => (obj[crr].subject ? { ...acc, [crr]: obj[crr] } : acc), {});

const searchElementInDataList = (data, labelKey, text) =>
  data.filter(item => item[labelKey].toUpperCase().includes(text));

const addPlusPrefixToCountyCode = countries =>
  countries
    .filter(country => country.dialCode)
    .map(country => ({
      ...country,
      dialCode: '+'.concat(country.dialCode),
    }));

const toNumber = strNumber =>
  strNumber ? (typeof strNumber === STRING_TYPE ? Number(strNumber.replaceAll(',', '')) : strNumber) : 0;

const editMode = (mode, edit) => !edit || mode;

const numberDecimalsCount = num => String(num).split('.')[1]?.length ?? 0;

const getUserMinMaxAgeRange = () => {
  const maxAge = moment(new Date()).subtract(MIN_AGE, 'year').format();
  const minAge = moment(new Date()).subtract(MAX_AGE, 'year').format();

  return {
    maxAge,
    minAge,
  };
};

const getSessionIdFromCookies = () => {
  const sessionId = Object.keys(Cookies.get()).find(val => val.indexOf('PHPSESSID_') === 0);
  return `${sessionId}=${Cookies.get(sessionId)}`;
};

const isFetchingColumnHelper = (val, isFetching) =>
  new Promise(resolve => {
    if (!isFetching) {
      resolve(val);
    }
  });

export {
  getDate,
  editMode,
  getCookie,
  getToday,
  blobToFile,
  capitalize,
  startUpload,
  formatNumber,
  downloadFile,
  getYesterday,
  createMarkup,
  groupBySteps,
  indexOfFilter,
  checkIsNumber,
  outsideFilters,
  fileSizeDisplay,
  copyToClipboard,
  priorityOrderBy,
  getCurrentMonth,
  addChildrenById,
  isFetchingColumnHelper,
  documentDirection,
  getRoleShortTitle,
  translateConstants,
  unshiftItemInArray,
  getExtensionByName,
  translateTextChange,
  checkValidCommission,
  getUserMinMaxAgeRange,
  selectUnSelectHandler,
  dropDownDataFormatter,
  getSessionIdFromCookies,
  stopPropagationHandler,
  searchElementInDataList,
  translateToasterMessage,
  documentDirectionChange,
  negativeAmountForReport,
  firstCharacterUpperCase,
  getArrayDifferenceByKey,
  addPlusPrefixToCountyCode,
  covertCpaStructureForEdit,
  getFilterCurrentMonthForAll,
  checkValidCommissionProducts,
  objectLanguageWithoutSubject,
  covertRevenueStructureForEdit,
  mapForSaveCommissionStructure,
  range,
  toNumber,
  numberDecimalsCount,
};
