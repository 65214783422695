import { Connection } from 'utils';
import {
  GET_PRODUCTS_COST_LOG,
  GET_AFFILIATE_CHANGE_STATUS_LOGS,
  GET_CRON_LIST,
  GET_POSTBACK_CHANGE_LOG,
} from 'constants/api';

export default {
  getPostbackChangeLog: filter => Connection.post(GET_POSTBACK_CHANGE_LOG, filter),
  getProductsCostLog: filterParams => Connection.post(GET_PRODUCTS_COST_LOG, filterParams),
  getAffiliatesChangeStatusLogs: filterParams => Connection.post(GET_AFFILIATE_CHANGE_STATUS_LOGS, filterParams),
  getCronList: filter => Connection.post(GET_CRON_LIST, filter),
};
