import { cloneDeep } from 'lodash';

const getGroupsList = async ({ state, effects }, filterParams) => {
  let groupList = cloneDeep(state.network.groups.groupList);
  groupList.isBusy = true;
  state.network.groups.groupList = groupList;

  const { needReset = true, ...data } = filterParams;

  const { result } = await effects.network.getNetworkGroups(data);

  groupList = cloneDeep(state.network.groups.groupList);
  if (result) {
    groupList.totalRecordsCount = Number(result.totalRecordsCount);
    groupList.records = [...(needReset ? [] : groupList.records), ...result.records];
  }

  groupList.isBusy = false;
  state.network.groups.groupList = groupList;
};

const getNextGroupList = ({ state, actions }, params) => {
  const start = state.network.groups.groupList.records.length;
  const { totalRecordsCount } = state.network.groups.groupList;

  if (start < totalRecordsCount) {
    actions.getGroupsList({
      ...params,
      start,
      limit: 10,
      needReset: false,
    });
  }
};

const deleteGroup = async ({ state, effects }, id) => {
  let groups = cloneDeep(state.network.groups);
  groups.deleteGroupActionState.isBusy = true;
  groups.deleteGroupActionState.isSucceeded = false;
  state.network.groups = groups;

  const result = await effects.network.deleteNetworkGroup(id);

  groups = cloneDeep(state.network.groups);
  if (result) {
    groups.groupList.totalRecordsCount--;
    groups.groupList.records = groups.groupList.records.filter(({ groupId }) => groupId !== id);
    groups.deleteGroupActionState.isSucceeded = true;
  }

  groups.deleteGroupActionState.isBusy = false;
  state.network.groups = groups;
};

const updateGroupDetails = async ({ state, effects }, data) => {
  let groups = cloneDeep(state.network.groups);
  groups.editGroupActionState.isBusy = true;
  groups.editGroupActionState.isSucceeded = false;
  state.network.groups = groups;

  const result = await effects.network.updateNetworkGroupDetails(data);

  groups = cloneDeep(state.network.groups);
  if (result) {
    groups.groupList.records = groups.groupList.records.map(group =>
      group.groupId === data.groupId ? { ...group, name: data.updatedDetails.name } : { ...group }
    );
    groups.editGroupActionState.isSucceeded = true;
  }

  groups.editGroupActionState.isBusy = false;
  state.network.groups = groups;
};

const getGroupMembers = async ({ state, effects }, filterParams) => {
  let groupMembers = cloneDeep(state.network.groups.groupMembers);
  groupMembers.isBusy = true;
  state.network.groups.groupMembers = groupMembers;

  const { result } = await effects.network.getUserListByGroupId(filterParams);

  groupMembers = cloneDeep(state.network.groups.groupMembers);
  if (result) {
    groupMembers.totalRecordsCount = Number(result.totalRecordsCount);
    groupMembers.records = result.records;
  }

  groupMembers.isBusy = false;
  state.network.groups.groupMembers = groupMembers;
};

const getUsersListByGroupId = async ({ state, effects }, filterParams) => {
  let usersListByGroupId = cloneDeep(state.network.groups.usersListByGroupId);
  usersListByGroupId.isBusy = true;
  state.network.groups.usersListByGroupId = usersListByGroupId;

  const { result } = await effects.network.getUserListByGroupId(filterParams);

  usersListByGroupId = cloneDeep(state.network.groups.usersListByGroupId);
  if (result) {
    usersListByGroupId.totalRecordsCount = Number(result.totalRecordsCount);
    usersListByGroupId.records = result.records;
  }

  usersListByGroupId.isBusy = false;
  state.network.groups.usersListByGroupId = usersListByGroupId;
};

const createGroup = async ({ state, effects }, data) => {
  let selectUsersTableActionState = cloneDeep(state.network.groups.selectUsersTableActionState);
  selectUsersTableActionState.isBusy = true;
  selectUsersTableActionState.isSucceeded = false;
  state.network.groups.selectUsersTableActionState = selectUsersTableActionState;

  const result = await effects.network.createNetworkGroup(data);

  selectUsersTableActionState = cloneDeep(state.network.groups.selectUsersTableActionState);
  if (result) {
    selectUsersTableActionState.isSucceeded = true;
  }

  selectUsersTableActionState.isBusy = false;
  state.network.groups.selectUsersTableActionState = selectUsersTableActionState;
};

const addToGroup = async ({ state, effects }, data) => {
  let selectUsersTableActionState = cloneDeep(state.network.groups.selectUsersTableActionState);
  selectUsersTableActionState.isBusy = true;
  selectUsersTableActionState.isSucceeded = false;
  state.network.groups.selectUsersTableActionState = selectUsersTableActionState;

  const result = await effects.network.addToNetworkGroup(data);

  selectUsersTableActionState = cloneDeep(state.network.groups.selectUsersTableActionState);
  if (result) {
    selectUsersTableActionState.isSucceeded = true;
  }

  selectUsersTableActionState.isBusy = false;
  state.network.groups.selectUsersTableActionState = selectUsersTableActionState;
};

const deleteUsersFromGroup = async ({ state, effects }, data) => {
  let groups = cloneDeep(state.network.groups);
  groups.removeUsersFromGroupState.isBusy = true;
  groups.removeUsersFromGroupState.isSucceeded = false;
  state.network.groups = groups;

  const result = await effects.network.deleteUsersFromNetworkGroup(data);

  groups = cloneDeep(state.network.groups);
  if (result) {
    groups.removeUsersFromGroupState.isSucceeded = true;
  }

  groups.removeUsersFromGroupState.isBusy = false;
  state.network.groups = groups;
};

export default {
  getGroupsList,
  deleteGroup,
  updateGroupDetails,
  getUsersListByGroupId,
  getGroupMembers,
  createGroup,
  addToGroup,
  deleteUsersFromGroup,
  getNextGroupList,
};
