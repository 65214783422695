import initialGeolocation from './geolocation';
import initialReports from './reports';
import initialProfile from './profile';
import initialLayout from './layout';
import initialAgents from './agents';
import initialPostBack from './postback';
import initialDeploy from './deploy';
import initialMessages from './messages';
import initialAffiliates from './affiliates';
import initialTestimonials from './testimonials';
import initialPlayers from './players';
import initialVerifications from './verifications';
import initialAccount from './account';
import initialNotification from './notification';
import initialCommissionPlans from './commisionPlan';
import initialNetwork from './network';
import initialContentManagement from './contentManagement';

const getSetup = (data = {}, first, second = null) => (data[first] && second ? data[first][second] : data[first]);

const neededReloadStructure = {
  addUser: false,
  addPlayer: false,
  transferToAgent: false,
  transferToPlayer: false,
};

const initialState = {
  account: initialAccount,
  loginData: undefined,
  wallets: {},
  forgotPasswordRequest: {
    isBusy: false,
    result: false,
  },
  deploy: initialDeploy,
  userInfo: {},
  mainSetup: {},
  viewUserInfo: {},
  notification: initialNotification,
  isLoggedIn: false,
  signInResult: undefined,
  defaultPage: null,
  affiliatesList: [],
  newMessageId: null,
  messages: initialMessages,
  neededReload: { neededReloadStructure },
  layout: initialLayout,
  reports: initialReports,
  profile: initialProfile,
  agents: initialAgents,
  affiliates: initialAffiliates,
  players: initialPlayers,
  postBack: initialPostBack,
  geolocation: initialGeolocation,
  testimonials: initialTestimonials,
  verifications: initialVerifications,
  commisionPlans: initialCommissionPlans,
  network: initialNetwork,
  contentManagement: initialContentManagement,
  isLoggedInAs: ({ mainSetup }) => !!Object.keys(getSetup(mainSetup, 'app', 'loginAsUser') || {}).length,
  app: ({ mainSetup }) => getSetup(mainSetup, 'app') || {},
  config: ({ mainSetup }) => getSetup(mainSetup, 'config') || {},
  generic: ({ mainSetup }) => getSetup(mainSetup, 'generic') || {},
  socialLinks: ({ mainSetup }) => getSetup(mainSetup, 'socialLinks') || [],
  mainCurrency: ({ mainSetup }) => getSetup(mainSetup, 'mainCurrency') || {},
  skinType: ({ mainSetup }) => getSetup(mainSetup, 'config', 'skinType') || null,
  currentUser: ({ mainSetup }) => getSetup(mainSetup, 'app', 'currentUser') || {},
  loginAsUser: ({ mainSetup }) => getSetup(mainSetup, 'app', 'loginAsUser') || {},
  partnerId: ({ mainSetup }) => getSetup(mainSetup, 'config', 'partnerId') || null,
  socialShareList: ({ mainSetup }) => getSetup(mainSetup, 'socialShareList') || [],
  userBalances: ({ mainSetup }) => getSetup(mainSetup, 'app', 'userBalances') || [],
  multiSkin: ({ mainSetup }) => getSetup(mainSetup, 'config', 'multySkin') || false,
  partnerUrl: ({ mainSetup }) => getSetup(mainSetup, 'config', 'partnerUrl') || null,
  allProducts: ({ mainSetup }) => getSetup(mainSetup, 'generic', 'allProducts') || [],
  currentUserId: ({ mainSetup }) => getSetup(mainSetup, 'app', 'currentUserId') || null,
  permissionsList: ({ mainSetup }) => getSetup(mainSetup, 'app', 'permissionsList') || {},
  scriptCodeTypeFooter: ({ mainSetup }) => getSetup(mainSetup, 'app', 'scriptCodeTypeFooter') || false,
  scriptCodeTypeHeader: ({ mainSetup }) => getSetup(mainSetup, 'app', 'scriptCodeTypeHeader') || false,
  defaultCommissionPlan: ({ mainSetup }) => getSetup(mainSetup, 'defaultCommissionPlan') || {},
  partnerSkinCustomization: ({ mainSetup }) => getSetup(mainSetup, 'partnerSkinCustomization') || {},
  partnerAffiliateUrl: ({ mainSetup }) => getSetup(mainSetup, 'config', 'partnerAffiliateUrl') || null,
  isMultiSkin: ({ mainSetup }) =>
    getSetup(mainSetup, 'config', 'multySkin') && (getSetup(mainSetup, 'generic', 'brands') || []).length > 1,
};

export default initialState;
