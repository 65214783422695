import { cloneDeep } from 'lodash';

const sendSmsVerificationCode = async ({ state, effects }, { data, t }) => {
  let verifications = cloneDeep(state.verifications);

  verifications.smsVerificationCode.isBusy = true;
  state.verifications = verifications;

  try {
    const result = await effects.verifications.sendSmsVerificationCode(data, t);

    verifications = cloneDeep(state.verifications);
    if (result) {
      verifications.smsVerificationCode.data = result;
      verifications.smsVerificationCode.isVisibleSMSVerify = true;

      state.verifications = verifications;
    }
  } finally {
    verifications.smsVerificationCode.isBusy = false;
    state.verifications = verifications;
  }
};

const setIsVisibleSMSVerify = ({ state }, data) => {
  state.verifications.smsVerificationCode = {
    ...state.verifications.smsVerificationCode,
    isVisibleSMSVerify: data,
  };
};

export default {
  sendSmsVerificationCode,
  setIsVisibleSMSVerify,
};
