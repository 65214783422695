import Toaster from '@geneui/components/Toaster';
import { Connection } from 'utils';
import { GET_VERIFICATION_DOC, RESET_TWO_FACTOR_AUTH_SECRET, SAVE_VERIFICATION_DOCUMENT } from 'constants/api';

export default {
  resetTwoFactorAuth: async (affiliateId, t) => {
    const { result } = await Connection.post(RESET_TWO_FACTOR_AUTH_SECRET, {
      affiliateId,
    });
    if (result) {
      Toaster.success({
        title: 'success',
        message: t('operation-has-completed-successfully'),
        iconProps: {
          isFilled: true,
        },
      });
    }
  },
  getVerificationDoc: affiliateId =>
    Connection.post(GET_VERIFICATION_DOC, {
      affiliateId,
    }),

  updateAffiliateVerificationDocument: document => Connection.post(SAVE_VERIFICATION_DOCUMENT, document),
};
