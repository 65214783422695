import Toaster from '@geneui/components/Toaster';
import { Connection } from 'utils';
import { RESPONSE_SUCCESS_INFO } from 'constants/defines';
import { GET_LAYOUT_ELEMENTS, SAVE_LAYOUT_ELEMENT } from 'constants/api';

const layout = {
  getLayoutElement: () => Connection.get(GET_LAYOUT_ELEMENTS),
  saveLayoutElement: async data => {
    const { result } = await Connection.post(SAVE_LAYOUT_ELEMENT, data);

    if (result) {
      Toaster.success(RESPONSE_SUCCESS_INFO);
    }

    return result;
  },
};

export default layout;
