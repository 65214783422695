import React, { useRef } from 'react';
import AuthCode from 'react-auth-code-input';

import Button from '@geneui/components/Button';

import Loader from 'components/Loader';

import { useStore } from 'store';
import { useTranslator } from 'utils';
import useTimer from 'utils/hooks/useTimer';

const SMSVerify = ({ registerState, onChange, onBack }) => {
  const { t } = useTranslator();
  const {
    state: {
      verifications: {
        smsVerificationCode: {
          isBusy,
          data: { attempts, expireTime },
        },
      },
    },
    actions: { sendSmsVerificationCode },
  } = useStore();

  const inputRef = useRef(null);

  const timeRemaining = useTimer(expireTime, isBusy);

  const onResend = () => {
    sendSmsVerificationCode({
      data: {
        affiliate: registerState,
        isAgent: false,
      },
      t,
    });

    void inputRef.current?.clear();
  };

  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;

  const isResendDisabled = attempts ? timeRemaining >= 180 : timeRemaining > 0;

  const time = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

  return (
    <div className="smsVerify">
      <Loader isBusy={isBusy} />
      <Button appearance="minimal" icon="bc-icon-arrow-back" onClick={onBack} className="smsVerifyBack">
        {t('back')}
      </Button>
      <div className="smsVerifyContainer">
        <p>
          {`${t('please-enter-the-verification-code-we-send-to-your-mobile')} **${registerState?.cellPhone?.slice(-2)}`}
        </p>
        <AuthCode
          ref={inputRef}
          onChange={onChange}
          allowedCharacters="numeric"
          containerClassName="container"
          inputClassName="input"
        />
        <p>{t('wait-minutes-to-get-verification-code', { time })}</p>
        <Button color="primary" appearance="minimal" disabled={isResendDisabled} className="resend" onClick={onResend}>
          {t('resend-code')}
        </Button>
      </div>
    </div>
  );
};

export default SMSVerify;
