import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Form from '@geneui/components/Form';
import Icon from '@geneui/components/Icon';
import Button from '@geneui/components/Button';

import FormField from 'components/FormField';
import Loader from 'components/Loader';
import ReCaptchaComponent from 'components/ReCaptchaComponent';

import { useTranslator } from 'utils';
import { useConfig } from 'utils/hooks/useConfig';
import { FORGOT_PASSWORD_EMAIL, FORGOT_PASSWORD_OPTION, REGISTER, RESEND_VERIFICATION } from 'configs/urls';
import { CHANGE_ADMIN_PASSWORD_PAGE, ENTER_KEY_CODE, VERIFICATION_CODE } from 'constants/defines';

import { useLoginMutation } from 'reduxStore/services/authApi';
import { setDefaultPage } from 'reduxStore/slices/settingsSlice';

const GOOGLE_RE_CAPTCHA_ACTION = 'login';

const Login = () => {
  const dispatch = useDispatch();

  const { t } = useTranslator();
  const history = useHistory();
  const {
    isFetching: isMainSetupLoading,
    partnerSkinCustomization: {
      css: { loginHighlightColor, loginSignInButtonColors },
    },
    config: { isSms, partnerName, reCaptcha, reCaptchaKey },
  } = useConfig();

  const signInStructure = {
    username: null,
    password: null,
  };

  const [isValid, setIsValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [signInState, setSignInState] = useState(signInStructure);

  const isSignInDisabled = !signInState.password || !signInState.username || !isValid;

  const [login, { isLoading }] = useLoginMutation();

  const signInUser = async token => {
    try {
      const response = await login({
        ...signInState,
        ...(token
          ? {
              reCaptcha: {
                token,
                action: GOOGLE_RE_CAPTCHA_ACTION,
              },
            }
          : {}),
      });
      const { result, notification } = response?.data ?? {};

      if (!result && notification?.[0]?.code === VERIFICATION_CODE) {
        history.push(RESEND_VERIFICATION);
      } else if (result === CHANGE_ADMIN_PASSWORD_PAGE) {
        dispatch(setDefaultPage(result));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const changeSignInState = useCallback(
    e => {
      setSignInState(prevState => ({ ...prevState, ...e }));
    },
    [setSignInState]
  );

  const handleKeyboard = async (event, handleReCaptchaVerify) => {
    if (event.keyCode === ENTER_KEY_CODE && isValid && !isSignInDisabled) {
      handleReCaptchaVerify ? handleReCaptchaVerify() : await signInUser();
    }
  };

  const sharedProps = {
    inputSize: 'big',
    flexibility: 'full-width',
    cornerRadius: 'smooth-radius',
  };

  const loginForm = handleReCaptchaVerify => (
    <>
      <Form
        className="authorisationForm-bc"
        disableFooter
        onValidationChange={setIsValid}
        onKeyUp={event => handleKeyboard(event, handleReCaptchaVerify)}
      >
        <FormField
          {...sharedProps}
          id="userName"
          placeholder={t('username')}
          dataKey="username"
          label={t('username')}
          value={signInState.username}
          isValid={signInState.username && signInState.username.length > 0}
          onChange={changeSignInState}
          className="authorisationFormField-bc"
        />
        <FormField
          {...sharedProps}
          id="password"
          type={showPassword ? null : 'password'}
          dataKey="password"
          placeholder={t('password')}
          label={t('password')}
          value={signInState.password}
          onChange={changeSignInState}
          className="authorisationFormField-bc"
          isValid={signInState.password && signInState.password.length > 0}
          endAdornment={
            <Icon
              className="showPassword"
              type={showPassword ? 'bc-icon-activate-48' : 'bc-icon-inactivate-48'}
              onClick={() => setShowPassword(prev => !prev)}
            />
          }
        />
      </Form>
      <Link
        style={{ color: loginHighlightColor }}
        className="authorisationFormForgotPass-bc"
        to={isSms ? FORGOT_PASSWORD_OPTION : FORGOT_PASSWORD_EMAIL}
      >
        {t('forgot-password')}?
      </Link>
      <Button
        style={{ ...loginSignInButtonColors }}
        size="big"
        id="signIn"
        className="authorisationFormSubmit-bc"
        disabled={isSignInDisabled}
        onClick={() => (handleReCaptchaVerify ? handleReCaptchaVerify() : signInUser())}
      >
        {t('sign-in')}
      </Button>
    </>
  );

  return (
    <div className="authorisationFormColInner-bc">
      <h2 id="title" style={{ color: loginHighlightColor }} className="authorisationFormTitle-bc">
        {partnerName}
      </h2>
      <h4 id="subTitle" className="authorisationFormSubtitle-bc">
        {t('sign-in')}
      </h4>
      <div className="authorisationFormCallToRegistration-bc">
        <p>
          {`${t('new-to', { name: partnerName })}`}?
          <Link style={{ color: loginHighlightColor }} to={REGISTER}>
            {t('sign-up')}
          </Link>
        </p>
      </div>
      {reCaptcha && reCaptchaKey ? (
        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
          <ReCaptchaComponent onSuccess={signInUser} action={GOOGLE_RE_CAPTCHA_ACTION}>
            {loginForm}
          </ReCaptchaComponent>
        </GoogleReCaptchaProvider>
      ) : (
        loginForm()
      )}
      <Loader isBusy={isLoading || isMainSetupLoading} />
    </div>
  );
};

export default Login;
