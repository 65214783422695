import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Loader from 'components/Loader';

import { useConfig } from 'utils/hooks/useConfig';
import { useLazyGetRegistrationFieldsQuery, useLazyGetSkinCurrenciesQuery } from 'reduxStore/services/configApi';
import { useCreateUserMutation } from 'reduxStore/services/userApi';

import RegistrationForm from './RegistrationForm';
import TermsAndConditions from './TermsAndConditions';
import RegistrationConfirm from './RegistrationConfirm';

const RegistrationRightSection = () => {
  const history = useHistory();
  const {
    config: { isPartnerCustomLanding },
  } = useConfig();

  const [getSkinCurrencies, { isFetching: isCurrenciesFetching }] = useLazyGetSkinCurrenciesQuery();
  const [getRegistrationFields, { isFetching: isRegistrationFetching }] = useLazyGetRegistrationFieldsQuery();

  const [createUser, { isLoading: isCreateUserLoading }] = useCreateUserMutation();

  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState();

  const closeRegisterPopup = useCallback(() => {
    history.push('/');
  }, [history]);

  const isLoading = isCurrenciesFetching || isRegistrationFetching || isCreateUserLoading;

  return (
    <div id="registrationContainer" className="authorisationFormCol-bc">
      <div className="registration-content-loader">
        <Loader isBusy={isLoading} />
        {!isPartnerCustomLanding ? (
          <div onClick={closeRegisterPopup} className="login-holder-item-close bc-icon-close" />
        ) : null}
        {!registrationSuccess ? (
          <>
            <RegistrationForm
              isLoading={isLoading}
              createUser={createUser}
              isVisible={!termsAndCondition}
              getSkinCurrencies={getSkinCurrencies}
              setTermsAndCondition={setTermsAndCondition}
              getRegistrationFields={getRegistrationFields}
              setRegistrationSuccess={setRegistrationSuccess}
            />
            <TermsAndConditions isVisible={termsAndCondition} setTermsAndCondition={setTermsAndCondition} />
          </>
        ) : (
          <RegistrationConfirm
            title={registrationSuccess?.title}
            text={registrationSuccess?.text}
            icon={registrationSuccess?.icon}
          />
        )}
      </div>
    </div>
  );
};

export default RegistrationRightSection;
