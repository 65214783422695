const useCarousel = () => (index, slide, space = 0) => {
  const carouselSlider = document.querySelector(`${slide}`);
  const element = carouselSlider.children[0] || null;
  const size = element ? element.clientWidth : 0;

  carouselSlider.style.transition = 'transform 0.4s ease-in-out';
  carouselSlider.style.transform = `translateX(${(-size - space) * index}px)`;
};

export default useCarousel;
