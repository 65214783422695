import Connection from 'utils/connection';

import Toaster from '@geneui/components/Toaster';

import { SEND_SMS_VERIFICATION_CODE } from 'constants/api';

const sendSmsVerificationCode = async (data, t) => {
  const { result } = await Connection.post(SEND_SMS_VERIFICATION_CODE, data);

  if (result) {
    Toaster.success({
      title: t('success'),
      message: t('verification-code-has-been-sent-to-your-mobile-phone'),
      iconProps: {
        isFilled: true,
      },
    });
  }

  return result;
};

export default { sendSmsVerificationCode };
