export const getTestimonials = async ({ state, effects }) => {
  state.testimonials = { ...state.testimonials, isLoading: true };
  try {
    const { result } = await effects.testimonials.getTestimonials();
    state.testimonials = { result, isLoading: false };
  } catch {
    state.testimonials = { ...state.testimonials, isLoading: false };
  }
};

export default {
  getTestimonials,
};
