export const WALLET_ROUTER_KEY = 'wallet';
export const NETWORK_USERS_ROUTER_KEY = 'network-users';
export const ACCOUNT_PROFILE_ROUTER_KEY = 'account-profile';
export const ACCOUNT_COMMISSION_ROUTER_KEY = 'account-commission';
export const ACCOUNT_CARRY_OVER_ROUTER_KEY = 'account-carry-over';
export const WITHDRAWALS_MY_REQUEST_ROUTER_KEY = 'withdrawals-my-requests';
export const WITHDRAWALS_SUB_REQUEST_ROUTER_KEY = 'withdrawals-sub-requests';

export const NOTIFICATION_ROUTES = {
  [ACCOUNT_PROFILE_ROUTER_KEY]: '/account/:id/account',
  [ACCOUNT_COMMISSION_ROUTER_KEY]: '/account/:id/commission',
  [ACCOUNT_CARRY_OVER_ROUTER_KEY]: '/account/:id/carryOver',
  [NETWORK_USERS_ROUTER_KEY]: '/network/customers',
  [WITHDRAWALS_MY_REQUEST_ROUTER_KEY]: '/withdrawals/withdrawals',
  [WITHDRAWALS_SUB_REQUEST_ROUTER_KEY]: '/withdrawals/subWithdrawals',
  [WALLET_ROUTER_KEY]: '/wallet',
};

export const NOTIFICATION_STATUS = {
  READ: 'READ',
  UNREAD: 'UNREAD',
};
