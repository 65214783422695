import classifiers from './classifiers';

const baseURL = '/';

const dateFormat = 'YYYY/MM/DD';
const dateFormatWithTime = 'YYYY/MM/DD HH:mm:ss';

const defaultLanguage = 'en-GB';
const defaultDirection = 'ltr';

export { baseURL, dateFormat, classifiers, defaultLanguage, defaultDirection, dateFormatWithTime };
