const getCurrentLangDictionary = async ({ state, effects }, keyName) => {
  state.contentManagement = {
    ...state.contentManagement,
    termsAndConditions: {
      ...state.contentManagement.termsAndConditions,
      isBusy: true,
    },
  };

  try {
    const { result } = await effects.contentManagement.getCurrentLangDictionary({ keyName });

    if (result) {
      state.contentManagement = {
        ...state.contentManagement,
        termsAndConditions: {
          ...state.contentManagement.termsAndConditions,
          result,
        },
      };
    }
  } finally {
    state.contentManagement = {
      ...state.contentManagement,
      termsAndConditions: {
        ...state.contentManagement.termsAndConditions,
        isBusy: false,
      },
    };
  }
};

export default { getCurrentLangDictionary };
