import Connection from 'utils/connection';
import * as api from 'constants/api';

const getAgentAvailableCurrencies = async ({ state }, fromState = false) => {
  if (state.agentAvailableCurrencies && fromState) return state.agentAvailableCurrencies;

  const { result } = await Connection.get(api.GET_AGENT_AVAILABLE_CURRENCIES);

  return (state.agentAvailableCurrencies = result);
};

const setNeededReloadState = ({ state }, { key, value }) => {
  return (state.neededReload[key] = value);
};

export default {
  setNeededReloadState,
  getAgentAvailableCurrencies,
};
