const initialState = {
  isBusy: false,
};

const initialVerifications = {
  smsVerificationCode: {
    ...initialState,
    isVisibleSMSVerify: false,
    data: {
      attempts: null,
      expireTime: null,
    },
  },
};

export default initialVerifications;
