import React, { useEffect } from 'react';

import Icon from '@geneui/components/Icon';

import Loader from 'components/Loader';

import { useStore } from 'store';
import { createMarkup } from 'utils/helpers';
import { useTranslator } from 'utils/translator';
import { LOG_OUT_PAGE_CALLS } from 'constants/defines';
import { useConfig } from 'utils/hooks/useConfig';

const TermsAndConditions = ({ isVisible, setTermsAndCondition }) => {
  const { t } = useTranslator();
  const {
    state: {
      contentManagement: {
        termsAndConditions: { isBusy, result },
      },
    },
    actions: { getCurrentLangDictionary },
  } = useStore();
  const {
    config: { partnerName },
  } = useConfig();

  const { TERMS_AND_CONDITIONS } = LOG_OUT_PAGE_CALLS;

  useEffect(() => {
    isVisible && !result && getCurrentLangDictionary(TERMS_AND_CONDITIONS);
  }, [isVisible, result]);

  return isVisible ? (
    <div className="termsAndCondition">
      <Icon type="bc-icon-arrow-back" onClick={() => setTermsAndCondition(false)} />
      <h2 className="login-holder-item-wrap-heading">{t('terms-and-conditions')}</h2>
      <div className="login-holder-item-wrap-form">
        <div className="landing-page-p-c-scrollable" dangerouslySetInnerHTML={createMarkup(result, partnerName)} />
      </div>
      <Loader isBusy={isBusy} />
    </div>
  ) : null;
};

export default TermsAndConditions;
