const COLORS = {
  DELETE: '#e03f33',
  TAX: '#9171cb',
  BETS: '#ef5d8f',
  WINS: '#5fb4f6',
  BONUS: '#ffad5f',
  DEPOSIT: '#da5cac',
  WITHDRAW: '#ffad5f',
  EXPENCES: '#e03f33',
  FLAT_FEE: '#ffe76b',
  COMMISSION: '#207ede',
  NET_REVENUE: '#822691',
  NET_DEPOSIT: '#007e74',
  PLAYERS_CPA: '#ecacd5',
  WITHDRAWALS: '#ffca5f',
  GROSS_REVENUE: '#e86b80',
  CONVERTED_BONUSES: '#b763c6',
  ADMINISTRATIVE_COST: '#7dc681',
  AVAILABILITY: '#7dc681',
  CREDIT_LINE: '#3ec3ef',
  CREDIT: '#ffad5f',
  BALANCE: '#70c79a',
  CURRENT_WALLET: '#3ec3ef',
  AVAILABLE_WALLET: '#548e70',
  PENDING: '#fdc625',
  ACCEPTED: '#46904a',
  ON_LINE: '#46904a',
  OFF_LINE: '#e03f33',
  DENIED: '#e03f33',
  PASSIVE: '#f7776e',
  WAITING: '#FDBC69',
  ACTIVE: '#46904a',
  BLOCKED: '#9171cb',
  REOPEN: '#9171CB',
  DELETED: '#e03f33',
  AFFILIATE_SIGNUPS: '#3b4a95',
  PLAYERS_SIGNUPS: '#3b4a95',
  COMMISSIONS: '#47cfe0',
  COMMISSION_PlAN_NET_DEPOSIT_COLOR: '#7dc681',
  COMMISSION_PLAN_PLAYER_COUNT_COLOR: '#47b4a9',
  COMMISSION_PLAN_REVENUE_SHARE_COLOR: '#7481c9',
  COMMISSION_TYPE_REVENUE_SHARE: '#47cfe0',
  COMMISSION_TYPE_CPA: '#ef5d8f',
  COMMISSION_TYPE_REFERRAL: '#f7776e',
  COMMISSION_TYPE_HYBRID: '#9171cb',
  COMMISSION_PAYMENT_CONDITIONS: '#ef5d8f',
  PLAYER_ID: '#f48eb0',
  CONTENT_MANAGEMENT_COLOR: '#b26c72',
  BRAND_CUSTOMIZATION_COLOR: '#9171cb',
  CURRENCY: '#cd92d7',
  CURRENCY_ICON_CONFIGURATION: '#7dc681',
  BTAG: '#9fa8da',
  EMAIL: '#80d5f4',
  POSTBACK_TYPE: '#7fcac3',
  REGISTRATION_DATE: '#7fcac3',
  DEPOSIT_AMOUNT: '#f9a19a',
  USERNAME: '#db8e94',
  DEPOSIT_COUNT: '#db8e94',
  PENDING_BANNERS_COLOR: '#f3ce12',
  PENDING_NEW_USERS: '#e03f33',
  PENDING_PAYMENT_REQUEST: '#e03f33',
  NEW_REGISTERED_PLAYERS: '#7481c9',
  ALL_BANNERS: '#abd47c',
  LAST_REGISTERED: '#9171cb',
  TOTAL_BETS_OPENED: '#9171cb',
  TOTAL_PAYABLE: '#7fcac3',
  ALL_PLAYERS: '#5fb4f6',
  NEW_REGISTERED_AGENTS: '#abd47c',
  YESTERDAY_COMMISSIONS: '#9171cb',
  TOTAL_BETS: '#7fcac3',
  POST_BACK_ICON: '#007e74',
  SPORTSBOOK: '#55b400',
  POKER: '#47cfe0',
  SLOTS: '#e03f33',
  LIVE_GAMES: '#ffe76b',
  VIRTUAL_GAMES: '#7481c9',
  SKILL_GAMES: '#abd47c',
  BETTING_GAMES: '#9171cb',
  POOL_BETTING_GAMES: '#7fcac3',
  TABLE_GAMES: '#5fb4f6',
  VIDEO_POKER: '#8011cb',
  NATIVE_POKER: '#47d47c',
  FANTASY_SPORT: '#aa126b',
  BET_ON_POLITICS: '#a73fff',
  SIGNUPS: '#47cfe0',
  DEPOSITING: '#e03f33',
  FIRST_DEPOSITING: '#ffe76b',
  ACTIVE_USERS: '#7481c9',
  REFERRAL_URL: '#fdc625',
  SUB_USERS_URL: '#7dc681',
  WIDGET_DEFAULT: '#ccc',
  DATA_FEED_FILTER_URL: '#fdc625',
  DATA_FEED_GLOBAL_URL: '#7dc681',
  WARNING: '#FFCA5F',
  ERROR: '#DF2F4D',
  PAYMENT: '#353739',
  ALL_PRODUCTS: '#df2f4d',
  MY_BALANCE: '#ffdc53',
  AGENT_BALANCE: '#7dc681',
  CONFIRM_ICON_CHANGES: '#abd47c',
  CONFIRM_ICON_DELETE: '#df2f4d',
  CONFIRM_ICON_WARNING: '#3ec3ef',
  CONFIRM_ICON_CALCULATE: '#9171cb',
  CONFIRM_ICON_INFO: '#ffca5f',
  BET_STATES_NEW: '#FFCA5F',
  BET_STATES_WON: '#007E74',
  BET_STATES_LOST: '#8E4148',
  BET_STATES_CASH_OUT: '#822691',
  BET_STATES_ACCEPTED: '#46904A',
  BET_STATES_RETURNED: '#207EDE',
  BET_STATES_REJECTED: '#E03F33',
  BET_STATES_NOT_RESULTED: '#F48F43',
  COMMISSION_DASHBOARD: '#e64e48',
  AFFILIATE_ID: '#1473e6',
  PROFIT: '#2bc784',
  CONTRIBUTION_COSTS: '#5fb4f6',
  LANDING_PAGE: '#47b4a9',
  MARKETING_SOURCE: '#ffca5f',
  MARKETING_SOURCE_ICON: '#ffffff',
  SIGN_IN_BUTTON_TEXT: '#ffffff',
  SIGN_IN_BUTTON_BORDER: '#ffffff',
  REGISTER_BUTTON_BORDER: '#ffffff',
  REGISTER_BUTTON_BACKGROUND: '#ffffff',
  REGISTER_BUTTON_TEXT: '#3c4043',
  PARTNER_MAIN_COLOR: '#6464EE',
  HEADER_BACKGROUND_COLOR: '#262626',
  HEADER_NAVIGATION_COLOR: '#ffffff',
  CUSTOM_MANUAL: '#7481c9',
  MANUAL: '#ffad5f',
  AUTOMATIC: '#39a69b',
  DELETE_CHAT_COLOR: '#E64E48',
  BUTTON_AVAILABLE_LIST: [
    '#3c4043',
    '#195e95',
    '#FFFFFF',
    '#7b0552',
    '#04444d',
    '#103f80',
    '#b23a5d',
    '#de701d',
    '#04444d',
    '#56565d',
  ],
  HEADER_BACKGROUND_AVAILABLE_LIST: [
    '#3c4043',
    '#195e95',
    '#262626',
    '#7b0552',
    '#04444d',
    '#103f80',
    '#b23a5d',
    '#de701d',
    '#04444d',
    '#56565d',
  ],
};

export const COLOR_LIST = [
  '#47b4a9',
  '#f48a3a',
  '#9171cb',
  '#da5cac',
  '#df2f4d',
  '#df2f4d',
  '#1473e6',
  '#47cfe0',
  '#cf6d41',
  '#5c3796',
  '#a5d7a7',
  '#007e74',
  '#2bc784',
  '#8e4148',
  '#ef5d8f',
  '#ffad5f',
  '#cd92d7',
  '#f9a19a',
  '#dbe670',
  '#9fa8da',
  '#abd47c',
  '#c5e1a4',
  '#db8e94',
  '#ffc99c',
  '#0091ce',
  '#3ec3ef',
  '#f48eb0',
  '#b10f78',
  '#b763c6',
  '#ffffff',
  '#70c79a',
  '#f48a3a',
  '#9171cb',
  '#da5cac',
  '#df2f4d',
  '#47cfe0',
  '#cf6d41',
  '#5c3796',
  '#a5d7a7',
  '#007e74',
  '#e64e48',
  '#8e4148',
  '#ef5d8f',
  '#ffad5f',
  '#cd92d7',
  '#f9a19a',
  '#dbe670',
  '#9fa8da',
  '#abd47c',
  '#c5e1a4',
  '#db8e94',
  '#ffc99c',
  '#0091ce',
  '#3ec3ef',
  '#f48eb0',
  '#b10f78',
  '#b763c6',
  '#ffffff',
  '#70c79a',
  '#ecacd5',
  '#df2f4d',
  '#47cfe0',
  '#cf6d41',
  '#5c3796',
  '#a5d7a7',
  '#007e74',
  '#e64e48',
  '#8e4148',
  '#ef5d8f',
  '#ffad5f',
];

export default COLORS;
