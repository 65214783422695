const getGeolocation = async ({ state, effects }) => {
  const { latitude, longitude } = await effects.geolocation.getGeolocation();
  state.geolocation.latitude = latitude;
  state.geolocation.longitude = longitude;
  const { data } = await effects.geolocation.getCountryByCoordinates({
    latitude,
    longitude,
  });
  state.geolocation.geocode = data;
};

export default { getGeolocation };
