const initialTransaction = {
  transactions: {
    isBusy: false,
    totalRecordsCount: undefined,
    records: [],
  },
};

const initialState = {
  isBusy: false,
  totalRecordsCount: undefined,
  records: [],
};

const initialReports = {
  transaction: initialTransaction,
  userPerformance: initialState,
  revenueBreakdown: initialState,
  referralReport: initialState,
};

export default initialReports;
