import axios from 'axios';
import { GEOCODE_URL } from 'configs/urls';

const getGeolocation = () =>
  new Promise((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        resolve(coords);
      }, reject);
    }
  });

const getCountryByCoordinates = ({ latitude, longitude }) =>
  axios.get(GEOCODE_URL, {
    params: {
      latitude,
      longitude,
    },
  });

export default { getGeolocation, getCountryByCoordinates };
